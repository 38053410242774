html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, caption,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
table, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
input,select,option,textarea  {
  outline:none;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
h1, h2, h3, h4, h5, h6, p, span {
  cursor: pointer;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
a, a:hover{
  color: inherit;
  text-decoration: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
html, body {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  font-family: "Lexend", "Microsoft YaHei", "lucida grande", "lucida sans unicode", lucida, helvetica, "Hiragino Sans GB", 'PingFang SC', "WenQuanYi Micro Hei", sans-serif;
}
.fl{
  float: left;
}
.fr{
  float: right;
  .button-group-item{
    padding-left: 3px;
  }
}
.clearfix{
  zoom:1;
  &:after{
    display:block;
    clear:both;
    content:"";
    visibility: hidden;
    height:0;
  }
}
