@import "./const.module";

@font-face {
  font-family: 'Lexend';
  src: url('../fonts/Lexend.ttf');
}

html, body {
  background-color: $colorDefaultBg;
  font-family: "Lexend", "Microsoft YaHei", "lucida grande", "lucida sans unicode", lucida, helvetica, "Hiragino Sans GB", 'PingFang SC', "WenQuanYi Micro Hei", sans-serif;
}

.no-padding {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.content-row {
  display: flex;
  flex-direction: row;
}

.center {
  text-align: center;
}

.primary {
  color: $colorPrimary;
}

.search-form {
  .ant-form-item-label {
    flex: 0 0 auto !important;
    padding: 0 !important;
  }
  .ant-form-item-control {
    flex: 1 0 auto !important;
  }
  .ant-row {
    flex-wrap: nowrap !important;
  }
}
.row-no-border {
  border-bottom: none !important;
  td {
    border-bottom: none !important;
  }
}
.small-text {
  font-size: 0.9em;
}
.table-footer {
  position: sticky;
  bottom: 0;
  z-index: 999;
  padding: 10px 16px;
  border-top: 1px solid $colorBorder;
  background: $colorDisabledBg;
}
.no-table-sticky-scroll {
  .ant-table-sticky-scroll {
    display: none !important;
  }
}
.hidden-scrollbar::-webkit-scrollbar {
  display: none !important;
}
.hidden-scrollbar {
  scrollbar-width: none !important;
  -ms-overflow-style: none !important;
}
.hidden-table-sticky-scrollbar {
  .ant-table-body {
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
  }
  .ant-table-body::-webkit-scrollbar {
    display: none !important;
  }
}
.success-icon {
  color: $colorSuccess;
}
.warning-icon {
  color: $colorWarning;
}
.modal-btns-center {
  .ant-modal-confirm-btns {
    width: 100%;
    text-align: center;
  }
}
.info-input-addon {
  .ant-input-number-group-addon {
    padding: 0 3px !important;
    background-color: $colorWhite;
  }
}
