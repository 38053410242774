//color
$colorWhite: #fff;
$colorPrimary: #2A61FF;
$colorBorder: #D9D9D9;
$colorDisabledBg: #fafafa;
$colorError: #F25248;
$colorDarkBg: #001529;
$colorSuccess: #52c41a;
$colorWarning: #faad14;
$colorMenuSidebarBg: #FFFFFF;
$colorDefaultBg: #F2F6FC;

//font-size
$fontSizeDesc: 0.9em;

//screen-size
$xsScreen: 480px;
$smScreen: 576px;
$mdScreen: 768px;
$lgScreen: 992px;
$xlScreen: 1200px;
$xxlScreen: 1600px;

//padding & margin
$contentPadding: 16px;
$contentPaddingSHorizontal: 10px;
$contentPaddingLHorizontal: 24px;

//common size
$headerContentHeight: 55px;
$headerTabsHeight: 30px;
$headerHeight: 85px;
$tableHeaderHeight: 55px;
$tableFooterHeight: 48px;

$contentMarginHeight: $headerHeight + 1 * $contentPadding;
// $contentOtherHeight: $contentMarginHeight + $contentPadding + $tableHeaderHeight + $tableFooterHeight;

:export {
  colorPrimary: $colorPrimary;
  colorDarkBg: $colorDarkBg;
  colorMenuSidebarBg: $colorMenuSidebarBg;
  colorDefaultBg: $colorDefaultBg;
  // contentOtherHeight: $contentOtherHeight;
}
